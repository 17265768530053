import { TextField, FilledTextFieldProps, StandardTextFieldProps, OutlinedTextFieldProps } from '@mui/material';
import { useEffect, useState, useRef } from 'react';

export interface FilledSearchProps extends FilledTextFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
    error?: boolean;
    delay?: number;
    maxLength?: number;
    upperCase?: boolean;
}
export interface StandardSearchProps extends StandardTextFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
    error?: boolean;
    delay?: number;
    maxLength?: number;
    upperCase?: boolean;
}
export interface OutlinedSearchProps extends OutlinedTextFieldProps {
    onChangeValue: (value: string) => void;
    label?: string;
    error?: boolean;
    delay?: number;
    maxLength?: number;
    upperCase?: boolean;
}

type SearchProps = FilledSearchProps | StandardSearchProps | OutlinedSearchProps;

export function Search({ onChangeValue, maxLength, delay, upperCase, ...props }: SearchProps): JSX.Element {
    const [inputValue, setInputValue] = useState<string>('');
    const DELAY_MILLI_SECONDS = delay ?? 1000; //  1 second

    const timer = useRef<NodeJS.Timeout>();

    const handleDelayedSearch = (query: string) => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            if (maxLength && query.length <= maxLength) {
                onChangeValue(query);
            } else {
                onChangeValue(query);
            }
        }, DELAY_MILLI_SECONDS);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const query = event.target.value;
        if (maxLength && query.length <= maxLength) {
            setInputValue(query);
            handleDelayedSearch(query);
        } else {
            setInputValue(query);
            handleDelayedSearch(query);
        }
    };

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <TextField
            id={Math.random().toString(36).substring(2, 9)}
            inputProps={{ style: { textTransform: upperCase ? 'uppercase' : 'none' }, maxLength: maxLength }}
            value={inputValue}
            onChange={handleInputChange}
            type="search"
            error
            {...props}
        />
    );
}
