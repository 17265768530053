import drop from '../../../assets/icons/drop_16_grey.svg';
import dropWhite from '../../../assets/icons/drop_16.svg';
import '../../../common/style/GlobalStyle.css';
import CategoriesMenu from './subcomponent/categoriesMenu/CategoriesMenu';
import { useEffect, useState } from 'react';
import {
    WbHeaderBar,
    WbHeaderButton,
    WbHeaderLanguageMenu,
    WbHeaderNavigation,
    WbHeaderNavigationItem,
    WbIcon,
} from '@workbench/react';
import SearchModal from './subcomponent/searchModal/SearchModal';
import LanguageSelector from '../../../common/i18n/LanguageSelector';
import { useTranslation } from '../../../common/i18n/useTranslation';
import { useCategoryMenuContext } from '../../category/context/CategoryContext';
import { useScrollLockContext } from '../../../utility/ScrollLockContext';
import fluidFinderIcon from '../../../assets/icons/fluidFinder.svg';
import fluidFinderWhiteIcon from '../../../assets/icons/fluidFinderWhite.svg';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../routes/routes';
import { isProdEnvirnoment } from '../../../utility/Helper';

const useStyles = makeStyles()((theme: Theme) => ({
    fluidFinderIcon: {
        width: '16px',
        height: '16px',
    },
}));

export const enum NavigationItems {
    CATEGORIES = 'categories',
    FLUID_FINDER = 'fluidFinder',
    NOTHING = 'nothing',
}

export default function Header() {
    const translate = useTranslation();
    const { classes } = useStyles();
    const { updateFlyInContainerOpen, getPreviouslyActiveNavItem, updatePreviouslyActiveNavItem } =
        useCategoryMenuContext();
    const { lockScroll, unlockScroll } = useScrollLockContext();
    const [searchModalOpen, setSearchModalOpen] = useState(false);
    const navigate = useNavigate();

    let navigation = document?.querySelector('wb-header-navigation');
    let items = navigation?.querySelectorAll('wb-header-navigation-item');

    useEffect(() => {
        const timeout = setTimeout(() => {
            initdocmentElements();

            addEventListeners();
        }, 0);

        return () => clearTimeout(timeout);
    }, []);

    const initdocmentElements = () => {
        navigation = document?.querySelector('wb-header-navigation');
        items = navigation?.querySelectorAll('wb-header-navigation-item');
    };

    const addEventListeners = () => {
        items?.forEach((item: any) => {
            item.addEventListener('click', (e: any) => {
                const previouslyActive = document.getElementById(getPreviouslyActiveNavItem()) as any;

                if (previouslyActive) {
                    previouslyActive.active = false;
                }
                closeLevel2FlyInToggle();

                // if not categories item selected, then reset categories menu
                if ((item.id as string) !== NavigationItems.CATEGORIES) {
                    updateFlyInContainerOpen(false);
                    closeFlyInContainer();
                    unlockScroll();
                    item.active = true;
                }

                // if the same nav item selected again, then reset everything and return
                if ((item.id as string) === getPreviouslyActiveNavItem()) {
                    resetNavigation();
                    return;
                }

                // if the selected nav item is categories, but the previous item was different
                // then open categories menu
                if (
                    (item.id as string) === NavigationItems.CATEGORIES &&
                    getPreviouslyActiveNavItem() !== NavigationItems.CATEGORIES
                ) {
                    updateFlyInContainerOpen(true);
                    lockScroll();
                }

                updatePreviouslyActiveNavItem(item.id as string);
            });
        });
    };

    const onOpenSearchModal = () => {
        setSearchModalOpen(true);
        resetNavigation();
    };
    const onCloseSearchModal = () => {
        setSearchModalOpen(false);
    };

    // deactivate navigation items and close categories menu
    const resetNavigation = () => {
        updateFlyInContainerOpen(false);
        closeFlyInContainer();
        updatePreviouslyActiveNavItem(NavigationItems.NOTHING);
        unlockScroll();
        items?.forEach((item: any) => {
            item.active = false;
        });
    };

    const closeLevel2FlyInToggle = () => {
        const flyInToggles = document.querySelectorAll('[toggles-fly-in]') as any;
        const flyIns = document.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in') as any;
        flyInToggles.forEach((toggle: any) => {
            const flyInIdentifier = toggle.getAttribute('toggles-fly-in');
            const flyInsToToggle = Array.from(flyIns).filter((flyIn: any) => flyIn.classList.contains(flyInIdentifier));

            const levelTwoFlyInToggles = Array.from(flyInsToToggle).filter(
                (flyInToToggle: any) => flyInToToggle.level === 2
            );

            levelTwoFlyInToggles?.forEach((levelTwoFlyInToggle: any) => {
                levelTwoFlyInToggle.open = false;
            });
        });
    };

    const closeFlyInContainer = () => {
        const flyInContainer = document.querySelector('wb-header-fly-in-container');
        const flyIns = document.querySelectorAll('wb-header-desktop-fly-in, wb-header-mobile-fly-in') as any;
        const targetTagName = 'wb-header-fly-in-container';
        const headerElements = ['wb-header-desktop-fly-in', 'wb-header-mobile-fly-in', 'wb-header-fly-in-container'];
        if (headerElements.includes(targetTagName)) {
            flyIns.forEach((flyIn: any) => {
                flyIn.open = false;
            });

            if (flyInContainer) {
                flyInContainer.open = false;
            }
        }
    };

    return (
        <>
            <WbHeaderBar starHref="/">
                <WbHeaderLanguageMenu slot="desktop-meta" aria-label="Language Menu">
                    <LanguageSelector />
                </WbHeaderLanguageMenu>
                <WbHeaderButton slot="desktop-meta" label="Open search" onClick={onOpenSearchModal}>
                    <WbIcon name="bds/search/16" aria-hidden="true" />
                </WbHeaderButton>

                <WbHeaderButton slot="tablet-meta-start" label="Open categories menu" toggles-fly-in="level-1">
                    <WbIcon src={dropWhite} aria-hidden="true" />
                </WbHeaderButton>
                {!isProdEnvirnoment() && (
                    <WbHeaderButton
                        slot="tablet-meta-start"
                        label="Open fluid finder menu"
                        onClick={() => navigate(Routes.fluid_finder)}
                    >
                        <WbIcon src={fluidFinderWhiteIcon} aria-hidden="true" className={classes.fluidFinderIcon} />
                    </WbHeaderButton>
                )}
                <WbHeaderLanguageMenu slot="tablet-meta-start" aria-label="Language Menu">
                    <LanguageSelector />
                </WbHeaderLanguageMenu>
                <WbHeaderButton slot="tablet-meta-end" label="Open search" onClick={onOpenSearchModal}>
                    <WbIcon name="bds/search/16" aria-hidden="true" />
                </WbHeaderButton>

                <WbHeaderButton slot="mobile-meta-start" label="Open categories menu" toggles-fly-in="level-1">
                    <WbIcon src={dropWhite} aria-hidden="true"></WbIcon>
                </WbHeaderButton>
                {!isProdEnvirnoment() && (
                    <WbHeaderButton
                        slot="mobile-meta-start"
                        label="Open fluid finder menu"
                        onClick={() => navigate(Routes.fluid_finder)}
                    >
                        <WbIcon src={fluidFinderWhiteIcon} aria-hidden="true" className={classes.fluidFinderIcon} />
                    </WbHeaderButton>
                )}
                <WbHeaderLanguageMenu slot="mobile-meta-end" aria-label="Language Menu">
                    <LanguageSelector />
                </WbHeaderLanguageMenu>
                <WbHeaderButton slot="mobile-meta-end" label="Open search" onClick={onOpenSearchModal}>
                    <WbIcon name="bds/search/16" aria-hidden="true" />
                </WbHeaderButton>
            </WbHeaderBar>

            <WbHeaderNavigation slot="desktop-meta" aria-label="Main Navigation">
                <WbHeaderNavigationItem id={NavigationItems.CATEGORIES} toggles-fly-in="level-1">
                    <WbIcon src={drop} slot="icon" />
                    {translate('Categories')}
                </WbHeaderNavigationItem>
                {!isProdEnvirnoment() && (
                    <WbHeaderNavigationItem
                        id={NavigationItems.FLUID_FINDER}
                        onClick={() => navigate(Routes.fluid_finder)}
                    >
                        <WbIcon slot="icon" src={fluidFinderIcon} />
                        {translate('FluidFinderText')}
                    </WbHeaderNavigationItem>
                )}
            </WbHeaderNavigation>

            <CategoriesMenu />

            <SearchModal open={searchModalOpen} handleOpen={onOpenSearchModal} handleClose={onCloseSearchModal} />
        </>
    );
}
