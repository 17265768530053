import React, { Suspense } from 'react';
import { MainSkeleton } from '../components/MainSkeleton';
import FluidFinder from '../components/fluidFinder/FluidFinder';
import { isProdEnvirnoment } from '../utility/Helper';
import { NotFound } from '../components/notFound/NotFound';

function FluidFinderRoute() {
    return <Suspense fallback={<MainSkeleton />}>{isProdEnvirnoment() ? <NotFound /> : <FluidFinder />}</Suspense>;
}

export default React.memo(FluidFinderRoute);
